import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Loading from "../Loading";
import AddGuestForm from "./AddGuestForm";
import { useNavigate } from "react-router-dom";
import SelectLocation from "../SelectLocation/SelectLocation";
import { DEFAULT_HOURS, defaultAppearance, DEFAUL_TIMEZONE } from "../../constants";
import Header from "../Header";
import FinishRegistration from "./FinishRegistration";
import VerificationSuccessful from "./VerificationSuccessful";
import { useParams, useLocation } from "react-router-dom";
import { routes } from "../../api/routes";
import moment from "moment-timezone";
import { logError } from "../../services/log.service";
import Booking from "./Booking";
import ErrorExists from "./ErrorExists";
import { Settings } from "luxon";

function AddGuest(props) {
  const isBooking = props.isBooking ?? false;
  const navigate = useNavigate();
  const { id, catId } = useParams();
  const [loading, setLoading] = useState(true);
  const [service, setService] = useState();
  const [business, setBusiness] = useState(null);
  const [businesses, setBusinesses] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [plan, setPlan] = useState(null);
  const [appearance, setAppearance] = useState(null);
  const [visit, setVisit] = useState(null);
  const [accountSettings, setAccountSettings] = useState(false);

  useEffect(() => {
    let vid = localStorage.getItem(id + "previousConfirmation");
    getCheckInData(id, props.type, vid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, catId]);

  useEffect(() => {
    if (business?.settings?.timezone) {
      Settings.defaultZone = business.settings.timezone;
    }
  }, [business]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  function safeParse(x) {
    const parsed = parseInt(x, 10);
    if (isNaN(parsed)) {
      return undefined;
    }
    return parsed;
  }

  function postMessages(business, type) {
    postSetTitle(calculateTitle(business, type));
    postSetFooterText(calculateFooterText(business, type));
  }

  function postSetFooterText(text) {
    window.postMessage({ source: "waitly", command: "setFooterText", footerText: text });
  }
  function postSetTitle(title) {
    window.postMessage({ source: "waitly", command: "setTitle", title });
  }

  function selectLocationCallback(id) {
    const business = findBusinessById(id);
    setBusiness(business);
    postMessages(business, isBooking ? "book" : "checkin");
    if (isBooking) {
      navigate(`/book/${id}`);
    } else {
      navigate(`/checkin/${id}`);
    }
  }

  const findBusinessById = id => {
    for (let b of businesses) {
      if (b.id === id) {
        return b;
      }
    }
  };

  function getGetCategory(catId, categories) {
    const categoryId = safeParse(catId);
    if (categoryId && categories && categories.length > categoryId - 1) {
      return categories[categoryId - 1].name;
    } else {
      return undefined;
    }
  }

  function sendLoadingCompleteMessage() {
    if (window.parent) {
      window.parent.postMessage({ source: "waitly", command: "loadingComplete" }, "*");
    }
  }

  function calculateFooterText(business, type) {
    if (type === "checkin") {
      return `${business.name} partners with Waitly to manage their Waitlist. `;
    } else if (type === "book") {
      return `${business.name} partners with Waitly to manage their Bookings. `;
    }
  }

  function calculateTitle(business, type) {
    if (type === "checkin") {
      return `Waitlist check-in at ${business.name} `;
    } else if (type === "book") {
      return `Bookings at ${business.name}`;
    }
  }

  function getCheckInData(businessId, type, vid) {
    let url = `${routes.checkInData}?id=${businessId}&type=${type}&isBooking=${isBooking}`;
    if (!isBooking && vid) {
      url += `&vid=${vid}`;
    }

    fetch(url)
      .then(response => response.json())
      .then(json => {
        setAppearance({ ...defaultAppearance, ...(json.appearance || {}) });
        if (props.type === "checkin" || props.type === "book") {
          setService(getGetCategory(catId, json.businesses[0].settings.services));

          setBusiness(json.businesses[0]);
          postMessages(json.businesses[0], type);
        }
        setLoading(false);
        sendLoadingCompleteMessage();
        setAccountId(json.accountId);
        setBusinesses(json.businesses);
        setVisit(json.visit);
        setAccountName(json.accountName);
        setAccountSettings(json.accountSettings);
        setPlan(json.plan);
        if (shouldRedirectToCheckIn(json.businesses)) {
          redirectToCheckIn(json.businesses[0]);
        }
        if (shouldRedirectToBooking(json.businesses)) {
          redirectToBooking(json.businesses[0]);
        }
      })
      .catch(err => {
        console.log("err: ", err);
        logError("AddGuest fetch appearance", err, { businessId });
        // set default appearance
        setAppearance(defaultAppearance);
        setLoading(false);
      });
  }

  function getMaxPartySize() {
    return business?.settings?.waitlist?.maxPartySize || 8;
  }

  function getTimezone() {
    return business?.settings?.timezone || DEFAUL_TIMEZONE;
  }

  const isWithinWorkingHours = business => {
    const hours = getTodaysWorkingHours(business);
    const now = moment.tz(getTimezone());
    const begin = moment.tz(`${now.format("MM/DD/YYYY")} ${hours.begin}`, "MM/DD/YYYY H:mm", getTimezone());
    let end = moment.tz(`${now.format("MM/DD/YYYY")} ${hours.end}`, "MM/DD/YYYY H:mm", getTimezone());
    if (hours.end === "00:00") {
      end = end.add(1, "day");
    }

    return now.isBetween(begin, end);
  };

  const getOpenCount = businesses => {
    let count = 0;
    if (!businesses) {
      return count;
    }
    for (let b of businesses) {
      if (!dayMarkedAsClosed(b) && isWithinWorkingHours(b)) {
        count++;
      }
    }
    return count;
  };

  const isSomeOpenForBooking = businesses => {
    if (!businesses || !businesses.length) {
      return false;
    }

    return (
      businesses
        // map businesses to boolean values that mean if some day open
        .map(b => b.settings?.reservations?.hours?.some(h => !h.closed))
        // is some day open ([true, false].some(el => el === true))
        .some(el => el)
    );
  };

  const dayMarkedAsClosed = business => {
    if (business.settings.disabledForm) {
      return true;
    }
    const hours = getTodaysWorkingHours(business);
    return hours.closed;
  };

  const getTodaysWorkingHours = business => {
    const workingHours = business?.settings?.waitlist?.hours || DEFAULT_HOURS;
    const dayOfWeek = moment(moment.tz(business?.timezone).toDate(), "MM/DD/YYYY").day();
    return workingHours[dayOfWeek];
  };

  const shouldDisplayLinkNotAvailable = () => {
    return plan === "free" && !isBooking;
  };

  const shouldShowNotAcceptingCheckins = () => {
    if (isBooking) return false;
    return (
      (business &&
        (dayMarkedAsClosed(business) || !isWithinWorkingHours(business) || !business?.settings?.selfCheckIn)) ||
      getOpenCount(businesses) === 0
    );
  };

  const shouldShowNotAcceptingBooking = () => {
    if (!isBooking) return false;

    if (plan !== "pro" && plan !== "enterprise") {
      return true;
    }
    return (business && !business?.settings?.selfBooking) || !isSomeOpenForBooking(businesses);
  };

  const shouldRedirectToCheckIn = businesses => {
    return getOpenCount(businesses) === 1 && props.type === "select-location" && !isBooking;
  };

  const shouldRedirectToBooking = businesses => {
    return getOpenCount(businesses) === 1 && props.type === "select-location" && isBooking;
  };

  const redirectToCheckIn = business => {
    setBusiness(business);
    navigate(`/checkin/${business.id}`);
  };

  const redirectToBooking = business => {
    setBusiness(business);
    navigate(`/book/${business.id}`);
  };

  const renderContent = () => {
    if (shouldDisplayLinkNotAvailable()) {
      return <div className="checkin-error-message">Sorry. This link is not available.</div>;
    } else if (shouldShowNotAcceptingCheckins()) {
      return (
        <div>
          <Header business={business} appearance={appearance} fontColor={appearance.addGuestBusinessNameColor} />
          <div className="checkin-error-message">
            We are currently not accepting self check-ins.
            <br />
            <br />
            Please see our staff to be served.
          </div>
        </div>
      );
    } else if (shouldShowNotAcceptingBooking()) {
      return (
        <div>
          <Header business={business} appearance={appearance} fontColor={appearance.addGuestBusinessNameColor} />
          <div className="checkin-error-message">
            We are currently not accepting online reservations.
            <br />
            <br />
            Please see our staff to be served.
          </div>
        </div>
      );
    } else {
      return (
        <>
          <Header
            business={business}
            accountName={accountName}
            appearance={appearance}
            fontColor={appearance.addGuestBusinessNameColor}
          />
          <div>
            {props.type === "checkin" ? (
              <AddGuestForm
                visit={visit}
                accountName={accountName}
                businessId={id}
                business={business}
                maxPartySize={getMaxPartySize()}
                appearance={appearance}
                accountSettings={accountSettings}
                service={service}
              />
            ) : props.type === "checkin-confirm" ? (
              <FinishRegistration
                pendingVisitId={query.get("pendingVisitId")}
                code={query.get("code")}
                businessId={id}
                appearance={appearance}
              />
            ) : props.type === "checkin-exists-error" ? (
              <ErrorExists hash={query.get("hash")} appearance={appearance} />
            ) : props.type === "select-location" ? (
              <SelectLocation
                appearance={appearance}
                openCount={getOpenCount(businesses)}
                selectLocationCallback={selectLocationCallback}
                businesses={businesses}
              />
            ) : props.type === "book" ? (
              <Booking
                accountName={accountName}
                businessId={id}
                accountId={accountId}
                business={business}
                maxPartySize={getMaxPartySize()}
                appearance={appearance}
                service={service}
              />
            ) : (
              <VerificationSuccessful hash={query.get("hash")} appearance={appearance} />
            )}
          </div>
        </>
      );
    }
  };

  return loading ? (
    <div>
      <Loading />
    </div>
  ) : (
    <div style={{ background: appearance.addGuestBackground }}>
      {renderContent()}
      <Footer appearance={appearance} fixed={false} />
    </div>
  );
}

export default AddGuest;
